import React, { FC } from "react";
import "./Flow.styles.scss";

import stepA from "../../assets/images/flow/step-1.webp";
import stepB from "../../assets/images/flow/step-2.webp";
import stepC from "../../assets/images/flow/step-3.webp";

const Flow: FC = () => {
    return (
        <section className='flow'>
            <div className='flow__wrapper'>
                <div className='flow__headline'>
                    <div className='flow__heading'>
                        <h1 className='h1'>How does it work?</h1>
                    </div>
                    <svg className='flow__arrow' viewBox='0 0 98 98' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M30.625 79.625V73.5H69.1819L18.375 22.6931L22.6931 18.375L73.5 69.1819V30.625H79.625V79.625H30.625Z' fill='#464646'></path>
                    </svg>
                </div>
                <div className='flow__list'>
                    <div className='flow__step'>
                        <img src={stepA} alt='' className='flow__step-image' />
                        <div className='flow__step-info'>
                            <div className='flow__step-headline'>
                                <div className='flow__step-label'>
                                    <div className='text--button'>Step 1</div>
                                </div>
                                <div className='flow__step-title'>
                                    <h4>Upload your furniture product image</h4>
                                </div>
                            </div>
                            <div className='flow__step-para'>
                                <div className='text-3'>Simply upload a photo of your furniture piece. It can be a basic picture taken with your phone.</div>
                            </div>
                        </div>
                    </div>
                    <div className='flow__step'>
                        <img src={stepB} alt='' className='flow__step-image' />
                        <div className='flow__step-info'>
                            <div className='flow__step-headline'>
                                <div className='flow__step-label'>
                                    <div className='text--button'>Step 2</div>
                                </div>
                                <div className='flow__step-title'>
                                    <h4>Rupert AI generates dozens of lifestyle scene variations in minutes</h4>
                                </div>
                            </div>
                            <div className='flow__step-para'>
                                <div className='text-3'>
                                    Our AI places your furniture in beautifully designed room settings with perfect lighting, complementary decor, and
                                    personalised interior.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flow__step'>
                        <img src={stepC} alt='' className='flow__step-image' />
                        <div className='flow__step-info'>
                            <div className='flow__step-headline'>
                                <div className='flow__step-label'>
                                    <div className='text--button'>Step 3</div>
                                </div>
                                <div className='flow__step-title'>
                                    <h4>Only pay for what you decide to use</h4>
                                </div>
                            </div>
                            <div className='flow__step-para'>
                                <div className='text-3'>
                                    Generate as many lifestyle images as you need, but only pay for the ones you download and use. No charges for the creation
                                    process—just the final results you love.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Flow;
